import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';

const CarModelModal = ({ open, onClose, carModel, onSave, isEditing, carMakeId }) => {
    const [modelNo, setModelNo] = useState('');

    useEffect(() => {
        // Set the model number if editing, else reset
        setModelNo(isEditing && carModel ? carModel.model_no : '');
    }, [carModel, isEditing]);

    const handleSave = () => {
        if (onSave) {
            onSave({
                id: carModel ? carModel.id : undefined, // Use existing ID if editing
                model_no: modelNo,
                make_id: carMakeId  // Ensure this is passed from the parent component
            });
        }
    };
    
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{isEditing ? 'Edit Car Model' : 'Add Car Model'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="modelNo"
                    label="Model Number"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={modelNo}
                    onChange={(e) => setModelNo(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CarModelModal;
