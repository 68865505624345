import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
  const storedUser = localStorage.getItem('user');
  
  return storedUser ? element : <Navigate to="/" replace />;
};

export default PrivateRoute;
