import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Modal, Typography, useTheme, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addSubdivision, fetchDivisions } from '../../redux/actions/recordAction';
import { tokens } from "../../theme";

const AddSubdivisionModal = ({ open, handleClose }) => {
  const [division, setDivision] = useState('');
  const [subdivName, setSubdivName] = useState('');
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const divisions = useSelector((state) => state.record.divisions) || [];

  useEffect(() => {
    if (open) {
      dispatch(fetchDivisions());
    }
  }, [open, dispatch]);

  const handleAddSubdivision = () => {
    dispatch(addSubdivision(division, subdivName));
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        p={3} 
        bgcolor={theme.palette.background.paper}
        color={theme.palette.text.primary}
        borderRadius={2}
        boxShadow={3}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '300px',
        }}
      >
        <Typography variant="h6" mb={2}>Add Subdivision</Typography>
        <FormControl fullWidth mb={2}>
          <InputLabel style={{ color: theme.palette.text.primary }}>Division</InputLabel>
          <Select
            value={division}
            onChange={(e) => setDivision(e.target.value)}
            fullWidth
            style={{ color: theme.palette.text.primary }}
          >
            <MenuItem value="">
                <em>None</em>
            </MenuItem>
                  {divisions.map((division) => (
                      <MenuItem key={division.id} value={division.id}>
                          {division.div_name}
                      </MenuItem>
                  ))}
          </Select>
        </FormControl>
        <TextField 
          label="Subdivision Name" 
          value={subdivName} 
          onChange={(e) => setSubdivName(e.target.value)} 
          fullWidth 
          mb={2} 
          InputLabelProps={{
            style: { color: theme.palette.text.primary }
          }}
          InputProps={{
            style: { color: theme.palette.text.primary }
          }}
        />
        <Button 
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }} 
          onClick={handleAddSubdivision}
        >
          Add Subdivision
        </Button>
      </Box>
    </Modal>
  );
};

export default AddSubdivisionModal;
