// recordReducer.js
import { records } from "../actions/actionTypes";

const initialState = {
  
  error: null,
  drivers: [],
  driversadd: [],
  vehicles: [],
  roles: [],
  users:[],
  divisions: [],
  departments: [],
  subdivisions: [],
  subdivisionsm: [],
  vehicleHistory: [],

  vehicleAssignments: [],
  carMakes: [],
  carModels:[],
  

};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
  switch (action.type) {
    

    case records.DRIVERS:
      return { ...state, drivers: action.payload };

      case records.DRIVERS_SUCCESS:
      return { ...state, driversadd: action.payload };

    case records.DRIVERS_FAILURE:
      return { ...state, error: action.payload };

    case records.VEHICLES_SUCCESS:
      return { ...state, vehicles: action.payload };

    case records.VEHICLES_FAILURE:
      return { ...state, error: action.payload };  

      case records.ROLES_SUCCESS:
        return { ...state, roles: action.payload };
  
      case records.ROLES_FAILURE:
        return { ...state, error: action.payload };  
        
        case records.USERS_SUCCESS:
          return { ...state, users: action.payload };
    
        case records.USERS_FAILURE:
          return { ...state, error: action.payload };        

    //divisions
    case records.DIVISIONS_SUCCESS:
      return { ...state, divisions: action.payload, error: null };
    case records.ADD_DIVISION_SUCCESS:
      return { ...state, divisions: [...state.divisions, action.payload], error: null };
    case records.EDIT_DIVISION_SUCCESS:
      return {
        ...state,
        divisions: state.divisions.map(div =>
          div._id.$oid === action.payload._id.$oid ? action.payload : div
        ),
        error: null,
      };
    case records.DELETE_DIVISION_SUCCESS:
      return {
        ...state,
        divisions: state.divisions.filter(div => div._id.$oid !== action.payload),
        error: null,
      };
    case records.DIVISIONS_FAILURE:
      return { ...state, error: action.payload };

//subdivisons
case records.SUBDIVISIONS_SUCCESSM:
      return { ...state, subdivisionsm: action.payload, error: null };
    case records.ADD_SUBDIVISION_SUCCESS:
      return { ...state, subdivisionsm: [...state.subdivisions, action.payload], error: null };
    case records.EDIT_SUBDIVISION_SUCCESS:
      return {
        ...state,
        subdivisionsm: state.subdivisions.map(subdiv =>
          subdiv._id.$oid === action.payload._id.$oid ? action.payload : subdiv
        ),
        error: null,
      };
    case records.DELETE_SUBDIVISION_SUCCESSM:
      return {
        ...state,
        subdivisionsm: state.subdivisions.filter(subdiv => subdiv._id.$oid !== action.payload),
        error: null,
      };
    case records.SUBDIVISIONS_FAILUREM:
      return { ...state, error: action.payload };


//deprtments
case records.DEPARTMENTS_SUCCESS:
      return { ...state, departments: action.payload, error: null };
    case records.ADD_DEPARTMENT_SUCCESS:
      return { ...state, departments: [...state.departments, action.payload], error: null };
    case records.EDIT_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: state.departments.map(dept =>
          dept._id.$oid === action.payload._id.$oid ? action.payload : dept
        ),
        error: null,
      };
    case records.DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: state.departments.filter(dept => dept._id.$oid !== action.payload),
        error: null,
      };
    //assignments
      case records.FETCH_VEHICLE_ASSIGNMENTS_SUCCESS:
        return {
          ...state,
          vehicleAssignments: action.payload,
        };
      case records.FETCH_VEHICLE_ASSIGNMENTS_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case records.ASSIGN_VEHICLE_SUCCESS:
        return {
          ...state,
          vehicleAssignments: [...state.vehicleAssignments, action.payload],
        };
      case records.ASSIGN_VEHICLE_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case records.UPDATE_VEHICLE_ASSIGNMENT_SUCCESS:
        return {
          ...state,
          vehicleAssignments: state.vehicleAssignments.map(assignment =>
            assignment._id.$oid === action.payload._id.$oid ? action.payload : assignment
          ),
        };
      case records.UPDATE_VEHICLE_ASSIGNMENT_FAILURE:
        return {
          ...state,
          error: action.payload,
        };

        
      case 'FETCH_SUBDIVISIONS_BY_DIVISION_SUCCESS':
        return { ...state, subdivisions: action.payload };
  
      case 'FETCH_SUBDIVISIONS_BY_DIVISION_FAILURE':
        return { ...state, subdivisions: [], error: action.payload };

        case 'FETCH_VEHICLE_HISTORY_SUCCESS':
          return {
            ...state,
            vehicleHistory: action.payload
          };  


          case records.FETCH_CAR_MAKES_SUCCESS:
            return {
              ...state,
              carMakes: action.payload,
              error: null,
            };
          case records.FETCH_CAR_MAKES_FAILURE:
          case records.ADD_CAR_MAKE_FAILURE:
          case records.UPDATE_CAR_MAKE_FAILURE:
          case records.DELETE_CAR_MAKE_FAILURE:
            return {
              ...state,
              error: action.payload,
            };
          case records.ADD_CAR_MAKE_SUCCESS:
            return {
              ...state,
              carMakes: [...state.carMakes, action.payload],
              error: null,
            };

            
          case records.UPDATE_CAR_MAKE_SUCCESS:
            return {
              ...state,
              carMakes: state.carMakes.map((make) =>
                make.id === action.payload.id ? { ...make, ...action.payload } : make
              ),
              error: null,
            };
          case records.DELETE_CAR_MAKE_SUCCESS:
            return {
              ...state,
              carMakes: state.carMakes.filter((make) => make.id !== action.payload),
              error: null,
            };    

         
            case records.FETCH_CAR_MODELS_SUCCESS:
              return {
                ...state,
                carModels: action.payload,
              };
            case records.ADD_CAR_MODEL_SUCCESS:
              return {
                ...state,
                carModels: [...state.carModels, action.payload],
              };
            case records.UPDATE_CAR_MODEL_SUCCESS:
              return {
                ...state,
                carModels: state.carModels.map(model => model.id === action.payload.id ? action.payload : model),
              };
            case records.DELETE_CAR_MODEL_SUCCESS:
              return {
                ...state,
                carModels: state.carModels.filter(model => model.id !== action.payload),
              };

        case records.LOGOUT:
          return initialState;

    default:
      return state;
  }
}
