import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from 'react-redux';
import {fetchUsers, resetPassword, updateUserRole, updateUserDivision, fetchRoles, fetchDivisions} from '../../redux/actions/recordAction';
import Header from "../../components/Header";
import AddUserModal from './AddUserModal';
import ChangeRoleModal from './ChangeRoleModal';
import ChangeDivisionModal from './ChangeDivisionModal';
import { tokens } from "../../theme";

const AddUser = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const users = useSelector((state) => state.record.users) || [];
    const roles = useSelector((state) => state.record.roles) || [];
    const divisions = useSelector((state) => state.record.divisions) || [];
    const error = useSelector((state) => state.record.error);
    const [openAddUserModal, setOpenAddUserModal] = useState(false);
    const [openChangeRoleModal, setOpenChangeRoleModal] = useState(false);
    const [openChangeDivisionModal, setOpenChangeDivisionModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        dispatch(fetchUsers());
        dispatch(fetchRoles());
        dispatch(fetchDivisions());
    }, [dispatch]);

    const handleResetPassword = (username) => {
        const password = prompt("Enter new password:");
        if (password) {
            dispatch(resetPassword(username, password));
        }
    };

    const handleOpenChangeRoleModal = (username) => {
        setSelectedUser(username);
        setOpenChangeRoleModal(true);
    };

    const handleCloseChangeRoleModal = () => {
        setOpenChangeRoleModal(false);
        setSelectedUser(null);
    };

    const handleRoleChange = (username, newRole) => {
        dispatch(updateUserRole(username, newRole));
    };

    const handleCloseAddUserModal = () => {
        setOpenAddUserModal(false);
        dispatch(fetchUsers());
    };

    const mapRoleIdToRoleName = (roleId) => {
        const role = roles.find(role => role._id.$oid === roleId.$oid);
        return role ? role.name : 'Unknown';
    };
    const handleOpenChangeDivisionModal = (username) => {
    setSelectedUser(username);
    setOpenChangeDivisionModal(true);  // Open a modal to change division
    };

    const handleCloseChangeDivisionModal = () => {
        setOpenChangeDivisionModal(false);
        setSelectedUser(null);
    };

    const handleDivisionChange = (username, newDivision) => {
        dispatch(updateUserDivision(username, newDivision));
    };

    const mapDivisionIdToDivisionName = (divisionId) => {
    // Extract ID from possible object format or use string directly
    const id = typeof divisionId === 'object' && divisionId !== null && divisionId.$oid ? divisionId.$oid : divisionId;

    // Find the division by ID
    const division = divisions.find(div => div.id === id);

    return division ? division.div_name : 'Unknown';
};

    const columns = [
        { field: "id", headerName: "ID", flex: 1 },
        {
            field: "username",
            headerName: "Username",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "role",
            headerName: "Role",
            flex: 1,
            valueGetter: (params) => mapRoleIdToRoleName(params.row.role),
        },
        {
            field: "division",
            headerName: "Division",
            flex: 1,
            valueGetter: (params) => {
            return mapDivisionIdToDivisionName(params.row.division);
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleResetPassword(params.row.username)}
                    >
                        Reset Password
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenChangeRoleModal(params.row.username)}
                        style={{ marginLeft: 10 }}
                    >
                        Change Role
                    </Button>
                    <Button
                    variant="contained"
                    color="warning"
                    onClick={() => handleOpenChangeDivisionModal(params.row.username)}
                    style={{ marginLeft: 10 }}
                >
                    Change Division
                </Button>
                </>
            ),
        }
    ];

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="USERS" subtitle="Managing the Users" />
                <Button sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                }} onClick={() => setOpenAddUserModal(true)}>
                    Add User
                </Button>
                <AddUserModal open={openAddUserModal} handleClose={handleCloseAddUserModal} />
                <ChangeRoleModal 
                    open={openChangeRoleModal} 
                    handleClose={handleCloseChangeRoleModal} 
                    handleRoleChange={handleRoleChange} 
                    roles={roles} 
                    username={selectedUser} 
                />
                <ChangeDivisionModal
                    open={openChangeDivisionModal}
                    handleClose={handleCloseChangeDivisionModal}
                    handleDivisionChange={handleDivisionChange}
                    username={selectedUser}
                    divisions={divisions}
                />
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={users.map((user, index) => ({ ...user, id: index + 1 }))}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
        </Box>
    );
};

export default AddUser;
