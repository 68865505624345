import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from 'react-redux';
import { fetchDepartments, deleteDepartment, fetchDivisions, fetchSubdivisions } from '../../redux/actions/recordAction';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import AddDepartmentModal from './AddDepartmentModal';
import EditDepartmentModal from './EditDepartmentModal';

const Departments = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const departments = useSelector((state) => state.record.departments) || [];
  const divisions = useSelector((state) => state.record.divisions) || [];
  const subdivisions = useSelector((state) => state.record.subdivisions) || [];
  const error = useSelector((state) => state.record.error);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentDepartment, setCurrentDepartment] = useState(null);

  useEffect(() => {
    dispatch(fetchDepartments());
    dispatch(fetchDivisions());
    // dispatch(fetchSubdivisions());
  }, [dispatch]);

  const handleCloseAddModal = () => {
    setOpenAdd(false);
    dispatch(fetchDepartments());
  };

  const handleCloseEditModal = () => {
    setOpenEdit(false);
    dispatch(fetchDepartments());
  };

  const handleEdit = (department) => {
    setCurrentDepartment(department);
    setOpenEdit(true);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "div_name", headerName: "Division", flex: 1 },
    { field: "subdiv_name", headerName: "Subdivision Name", flex: 1 },
    { field: "department_name", headerName: "Department Name", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEdit(params.row)}
            style={{ marginRight: 8 }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => dispatch(deleteDepartment(params.row._id.$oid))}
          >
            Delete
          </Button>
        </div>
      ),
    }
  ];

  const processedDepartments = departments.map((department, index) => ({
    ...department,
    id: index + 1,
  }));
//  console.log(processedDepartments)
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DEPARTMENTS" subtitle="Managing the Departments" />
        <Button sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
        }} onClick={() => setOpenAdd(true)}>
          Add Department
        </Button>
        <AddDepartmentModal open={openAdd} handleClose={handleCloseAddModal} divisions={divisions} subdivisions={subdivisions} />
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
              border: "none",
          },
          "& .MuiDataGrid-cell": {
              borderBottom: "none",
          },
          "& .name-column--cell": {
              color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
          },
      }}
      >
        <DataGrid
          rows={processedDepartments}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      {currentDepartment && (
        <EditDepartmentModal
          open={openEdit}
          handleClose={handleCloseEditModal}
          department={currentDepartment}
          divisions={divisions}
          subdivisions={subdivisions}
        />
      )}
    </Box>
  );
};

export default Departments;
