import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Modal, Typography, useTheme, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addVehicle, updateVehicle, fetchCarMakes, fetchCarModels } from '../../redux/actions/recordAction';
import { tokens } from "../../theme";
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import format from 'date-fns/format';

const AddVehicleModal = ({ open, handleClose, vehicle, isEditing }) => {
  const [imei, setImei] = useState('');
  const [tracker_phone, setPhoneNo] = useState('');
  const [civilian_plate, setCivilianRegNo] = useState('');
  const [gk_plate, setGkRegNo] = useState('');
  const [chasis_no, setChasisNo] = useState('');
  const [engine_no, setEngineNo] = useState('');
  const [purchase_date, setPurchaseDate] = useState(null);
  const [make_id, setMakeId] = useState('');
  const [model_id, setModelId] = useState('');
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const carMakes = useSelector(state => state.record.carMakes) || [];
  const carModels = useSelector(state => state.record.carModels) || [];

  useEffect(() => {
    dispatch(fetchCarMakes());
  }, [dispatch]);

  useEffect(() => {
    if (make_id) {
      dispatch(fetchCarModels(make_id));
    }
  }, [dispatch, make_id]);

  useEffect(() => {
    if (isEditing && vehicle) {
      setImei(vehicle.imei);
      setPhoneNo(vehicle.tracker_phone);
      setCivilianRegNo(vehicle.civilian_plate);
      setGkRegNo(vehicle.gk_plate);
      setChasisNo(vehicle.chasis_no);
      setEngineNo(vehicle.engine_no);
      setPurchaseDate(vehicle.purchase_date ? new Date(vehicle.purchase_date) : null);
      setMakeId(vehicle.make_id || '');
      setModelId(vehicle.model_id || '');
    } else {
      setImei('');
      setPhoneNo('');
      setCivilianRegNo('');
      setGkRegNo('');
      setChasisNo('');
      setEngineNo('');
      setPurchaseDate(null);
      setMakeId('');
      setModelId('');
    }
  }, [isEditing, vehicle]);

  const handleAddOrUpdateVehicle = () => {
    const formattedDate = purchase_date ? format(purchase_date, 'yyyy-MM-dd') : '';

    if (isEditing) {
      dispatch(updateVehicle(imei, tracker_phone, civilian_plate, gk_plate, chasis_no, engine_no, formattedDate, make_id, model_id));
    } else {
      dispatch(addVehicle(imei, tracker_phone, civilian_plate, gk_plate, chasis_no, engine_no, formattedDate, make_id, model_id));
    }
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        p={3} 
        bgcolor={theme.palette.background.paper}
        color={theme.palette.text.primary}
        borderRadius={2}
        boxShadow={3}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '600px',
        }}
      >
        <Typography variant="h6" mb={2}>{isEditing ? "Edit Vehicle" : "Add Vehicle"}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField 
              label="IMEI" 
              value={imei} 
              onChange={(e) => setImei(e.target.value)} 
              fullWidth 
              disabled={isEditing}
              InputLabelProps={{
                style: { color: theme.palette.text.primary }
              }}
              InputProps={{
                style: { color: theme.palette.text.primary }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField 
              label="Phone Number" 
              value={tracker_phone} 
              onChange={(e) => setPhoneNo(e.target.value)} 
              fullWidth 
              InputLabelProps={{
                style: { color: theme.palette.text.primary }
              }}
              InputProps={{
                style: { color: theme.palette.text.primary }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField 
              label="Civilian Registration Number" 
              value={civilian_plate} 
              onChange={(e) => setCivilianRegNo(e.target.value)} 
              fullWidth 
              InputLabelProps={{
                style: { color: theme.palette.text.primary }
              }}
              InputProps={{
                style: { color: theme.palette.text.primary }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField 
              label="GK Registration Number" 
              value={gk_plate} 
              onChange={(e) => setGkRegNo(e.target.value)} 
              fullWidth 
              InputLabelProps={{
                style: { color: theme.palette.text.primary }
              }}
              InputProps={{
                style: { color: theme.palette.text.primary }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField 
              label="Chasis Number" 
              value={chasis_no} 
              onChange={(e) => setChasisNo(e.target.value)} 
              fullWidth 
              InputLabelProps={{
                style: { color: theme.palette.text.primary }
              }}
              InputProps={{
                style: { color: theme.palette.text.primary }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField 
              label="Engine Number" 
              value={engine_no} 
              onChange={(e) => setEngineNo(e.target.value)} 
              fullWidth 
              InputLabelProps={{
                style: { color: theme.palette.text.primary }
              }}
              InputProps={{
                style: { color: theme.palette.text.primary }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Purchase Date"
                value={purchase_date}
                onChange={(newValue) => setPurchaseDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                InputLabelProps={{
                  style: { color: theme.palette.text.primary }
                }}
                InputProps={{
                  style: { color: theme.palette.text.primary }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel style={{ color: theme.palette.text.primary }}>Car Make</InputLabel>
              <Select
                value={make_id}
                onChange={(e) => setMakeId(e.target.value)}
                fullWidth
                style={{ color: theme.palette.text.primary }}
              >
                {carMakes.map(make => (
                  <MenuItem key={make.id} value={make.id}>
                    {make.make_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel style={{ color: theme.palette.text.primary }}>Car Model</InputLabel>
              <Select
                value={model_id}
                onChange={(e) => setModelId(e.target.value)}
                fullWidth
                style={{ color: theme.palette.text.primary }}
              >
                {carModels.map(model => (
                  <MenuItem key={model.id} value={model.id}>
                    {model.model_no}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button 
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            mt: 3
          }} 
          onClick={handleAddOrUpdateVehicle}
        >
          {isEditing ? "Update Vehicle" : "Add Vehicle"}
        </Button>
      </Box>
    </Modal>
  );
};

export default AddVehicleModal;
