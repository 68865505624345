import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Typography, TextField, Grid, Button, Slider, CircularProgress,useTheme } from '@mui/material';
import { MapContainer, TileLayer, Marker, LayersControl, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { fetchVehicleHistory } from '../../redux/actions/recordAction';

const VehicleHistory = () => {
  const { imei } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { BaseLayer } = LayersControl;

  const coordinates = useSelector((state) => state.record.vehicleHistory) || [];
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const vehicleIcon = new L.Icon({
    iconUrl: require('./vehicle-icon.png'), // Ensure you have a vehicle icon image in the same directory
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const stopIcon = new L.Icon({
    iconUrl: require('./stop-icon.png'), // Ensure you have a stop icon image in the same directory
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });

  const handleReplayRequest = () => {
    setIsLoading(true);
    const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd HH:mm:ss') : '';
    const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd HH:mm:ss') : '';
    dispatch(fetchVehicleHistory(imei, formattedStartDate, formattedEndDate))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    let interval;
    if (isPlaying && coordinates.length > 0 && currentIndex < coordinates.length - 1) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 1000 / playbackSpeed);
    }
    return () => clearInterval(interval);
  }, [isPlaying, coordinates, currentIndex, playbackSpeed]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleSliderChange = (event, newValue) => {
    setPlaybackSpeed(newValue);
  };

  const handleTimelineChange = (event, newValue) => {
    setCurrentIndex(newValue);
    setIsPlaying(false); // Pause when manually adjusting the timeline
  };

  return (
    <Box m="20px">
      <Box display="flex" alignItems="center" mb="20px">
        <IconButton onClick={() => navigate('/vehicles')}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" ml="10px">
          Vehicle History - {imei}
        </Typography>
      </Box>

      <Box mb="20px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              sx={{
                backgroundColor: 'blue',
                color: 'white',
                fontSize: "14px",
                fontWeight: "bold",
                padding: "14px 30px",
              }}
              onClick={handleReplayRequest}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : "Fetch Coordinates"}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box mb="20px" display="flex" justifyContent="space-between" alignItems="center">
        <IconButton onClick={handlePlayPause}>
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <Slider
          value={currentIndex}
          onChange={handleTimelineChange}
          min={0}
          max={coordinates.length - 1}
          step={1}
          sx={{
            width: '100%',
            color: isDarkMode ? 'white' : 'black'
          }}
        />
      </Box>

      <Box mb="20px" display="flex" justifyContent="space-between">
        <Typography>Playback Speed</Typography>
        <Slider
          value={playbackSpeed}
          onChange={handleSliderChange}
          min={0.5}
          max={4}
          step={0.5}
          sx={{
            width: '200px',
            color: isDarkMode ? 'white' : 'black'
          }}
        />
      </Box>


      <Box
        height="65vh"
        width="100%"
        sx={{
          backgroundColor: 'lightgrey',
          borderRadius: '8px',
          overflow: 'hidden'
        }}
      >
        <MapContainer 
          center={[-1.2921, 36.8219]} // Coordinates to center map on Kenya
          zoom={6}
          style={{ height: "100%", width: "100%" }}
        >
          <LayersControl position="topright">
            <BaseLayer checked name="OpenStreetMap">
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
            </BaseLayer>
            <BaseLayer name="OpenTopoMap">
              <TileLayer
                url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://opentopomap.org">OpenTopoMap</a> contributors'
              />
            </BaseLayer>
            <BaseLayer name="Stamen Toner">
              <TileLayer
                url="https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://maps.stamen.com/">Stamen Design</a>'
              />
            </BaseLayer>
          </LayersControl>
          <AnimatingMarker coordinates={coordinates} currentIndex={currentIndex} vehicleIcon={vehicleIcon} stopIcon={stopIcon} />
        </MapContainer>
      </Box>
    </Box>
  );
};

const AnimatingMarker = ({ coordinates, currentIndex, vehicleIcon, stopIcon }) => {
  const map = useMap();
  const markerRef = useRef(null);
  const polylineRef = useRef(null);
  const stopMarkersRef = useRef([]);

  useEffect(() => {
    if (coordinates.length === 0) return;

    const latLngs = coordinates.map(coord => [coord.latitude, coord.longitude]);

    if (!polylineRef.current) {
      polylineRef.current = L.polyline([], { color: 'blue' }).addTo(map);
    }

    polylineRef.current.setLatLngs(latLngs.slice(0, currentIndex + 1));

    if (!markerRef.current) {
      markerRef.current = L.marker(latLngs[currentIndex], { icon: vehicleIcon }).addTo(map);
    } else {
      markerRef.current.setLatLng(latLngs[currentIndex]);
    }

    if (currentIndex === 0) {
      map.setView(latLngs[0], 13);
    } else {
      map.panTo(latLngs[currentIndex]);
    }

    // Clear previous stop markers
    stopMarkersRef.current.forEach(marker => marker.remove());
    stopMarkersRef.current = [];

    // Add stop markers dynamically
    for (let i = 1; i <= currentIndex; i++) {
      if (coordinates[i].speed <= 0.1) {
        const marker = L.marker(latLngs[i], {
          icon: stopIcon
        }).addTo(map).bindPopup(`Speed: ${coordinates[i].speed} km/h<br>Timestamp: ${coordinates[i].timestamp}`);

        stopMarkersRef.current.push(marker);
      }
    }
  }, [coordinates, currentIndex, map, vehicleIcon, stopIcon]);

  return null;
};

export default VehicleHistory;
