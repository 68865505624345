import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Modal, Typography, MenuItem, Select, FormControl, InputLabel, Grid, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { assignVehicle, fetchSubdivisionsByDivision } from '../../redux/actions/recordAction';
import { tokens } from "../../theme";

const AssignVehicleModal = ({ open, handleClose, vehicle, divisions, departments }) => {
  const [assignedDivision, setAssignedDivision] = useState('');
  const [assignedSubdivision, setAssignedSubdivision] = useState('');
  const [assignedDepartment, setAssignedDepartment] = useState('');
  const [assignedCategory, setAssignedCategory] = useState('');
  const [assignedPerson, setAssignedPerson] = useState('');
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const subdivisions = useSelector((state) => state.record.subdivisions.subdivisions) || [];

  useEffect(() => {
    if (assignedDivision) {
      dispatch(fetchSubdivisionsByDivision(assignedDivision));
    }
  }, [assignedDivision, dispatch]);

  const handleAssignVehicle = () => {
    dispatch(assignVehicle({
      vehicle: vehicle.id,
      assigned_division: assignedDivision,
      assigned_subdivision: assignedSubdivision,
      assigned_department: assignedDepartment,
      assigned_category: assignedCategory,
      assigned_person: assignedPerson
    }));
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        p={3} 
        bgcolor={theme.palette.background.paper}
        color={theme.palette.text.primary}
        borderRadius={2}
        boxShadow={3}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '600px',
        }}
      >
        <Typography variant="h6" mb={2}>Assign Vehicle</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel style={{ color: theme.palette.text.primary }}>Division</InputLabel>
              <Select
                value={assignedDivision}
                onChange={(e) => setAssignedDivision(e.target.value)}
                fullWidth
                style={{ color: theme.palette.text.primary }}
              >
               <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                  {divisions.map((division) => (
                      <MenuItem key={division.id} value={division.id}>
                          {division.div_name}
                      </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          {assignedDivision && (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel style={{ color: theme.palette.text.primary }}>Subdivision</InputLabel>
                <Select
                  value={assignedSubdivision}
                  onChange={(e) => setAssignedSubdivision(e.target.value)}
                  fullWidth
                  style={{ color: theme.palette.text.primary }}
                >
                  {subdivisions.map((subdiv) => (
                    <MenuItem key={subdiv.id} value={subdiv.id}>
                      {subdiv.subdiv_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {assignedSubdivision && (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel style={{ color: theme.palette.text.primary }}>Department</InputLabel>
                <Select
                  value={assignedDepartment}
                  onChange={(e) => setAssignedDepartment(e.target.value)}
                  fullWidth
                  style={{ color: theme.palette.text.primary }}
                >
                  {departments.map((dept) => (
                    <MenuItem key={dept._id} value={dept._id}>
                      {dept.department_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel style={{ color: theme.palette.text.primary }}>Category</InputLabel>
              <Select
                value={assignedCategory}
                onChange={(e) => setAssignedCategory(e.target.value)}
                fullWidth
                style={{ color: theme.palette.text.primary }}
              >
                <MenuItem value="pool">Pool</MenuItem>
                <MenuItem value="individual">Individual</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {assignedCategory === 'individual' && (
            <Grid item xs={12}>
              <TextField 
                label="Assigned Person" 
                value={assignedPerson} 
                onChange={(e) => setAssignedPerson(e.target.value)} 
                fullWidth 
                InputLabelProps={{
                  style: { color: theme.palette.text.primary }
                }}
                InputProps={{
                  style: { color: theme.palette.text.primary }
                }}
              />
            </Grid>
          )}
        </Grid>
        <Button 
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            mt: 3
          }} 
          onClick={handleAssignVehicle}
        >
          Assign Vehicle
        </Button>
      </Box>
    </Modal>
  );
};

export default AssignVehicleModal;
