import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, MenuItem, Select, FormControl, InputLabel, Modal, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector  } from "react-redux";
import { fetchRoles, fetchDivisions, registerUser } from "../../redux/actions/recordAction";
import { tokens } from "../../theme";
import { toast } from 'react-toastify';

const initialValues = {
    username: "",
    password: "",
    role: "",
    division:""
};

const userSchema = yup.object().shape({
    username: yup.string().required("required"),
    password: yup.string().required("required"),
    role: yup.string().required("required"),
    division: yup.string(),
});

const AddUserModal = ({ open, handleClose }) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const dispatch = useDispatch();
    const roles = useSelector((state) => state.record.roles) || [];
    const divisions = useSelector((state) => state.record.divisions) || [];
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        dispatch(fetchRoles());
        dispatch(fetchDivisions());
    }, [dispatch]);

    const handleFormSubmit = async (values) => {
        dispatch(registerUser(values.username, values.password, values.role, values.division));
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box 
                display="flex" 
                flexDirection="column" 
                justifyContent="center" 
                alignItems="center" 
                p={3} 
                bgcolor={theme.palette.background.paper}
                color={theme.palette.text.primary}
                borderRadius={2}
                boxShadow={3}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '400px',
                }}
            >
                <Typography variant="h6" mb={2}>Create New User</Typography>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={userSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                }}
                            >
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Username"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.username}
                                    name="username"
                                    error={!!touched.username && !!errors.username}
                                    helperText={touched.username && errors.username}
                                    sx={{ gridColumn: "span 4" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="password"
                                    label="Password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    name="password"
                                    error={!!touched.password && !!errors.password}
                                    helperText={touched.password && errors.password}
                                    sx={{ gridColumn: "span 4" }}
                                />
                                <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 4" }}>
                                    <InputLabel>Role</InputLabel>
                                    <Select
                                        name="role"
                                        value={values.role}
                                        onChange={(event) => {
                                            handleChange(event);
                                            setFieldValue("role", event.target.value);
                                        }}
                                        onBlur={handleBlur}
                                        error={!!touched.role && !!errors.role}
                                    >
                                        {roles.map((role) => (
                                            <MenuItem key={role.id} value={role.name}>
                                                {role.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 4" }}>
                                    <InputLabel>Division</InputLabel>
                                    <Select
                                        name="division"
                                        value={values.division}  // Ensure the value is set to values.division
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.division && !!errors.division}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {divisions.map((division) => (
                                            <MenuItem key={division.id} value={division.id}>
                                                {division.div_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box display="flex" justifyContent="end" mt="20px">
                                <Button type="submit" color="secondary" variant="contained">
                                    Create New User
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};

export default AddUserModal;
