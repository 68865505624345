// loginReducer.js
import { records } from "../actions/actionTypes";

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  error: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case records.LOGIN_SUCCESS:
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
        error: null,
      };
    case records.LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        error: action.payload,
      };
    case records.LOGOUT:
      localStorage.removeItem('user');
      return {
        ...state,
        user: null,
        error: null,
      };
    default:
      return state;
  }
};

export default loginReducer;
