// rootReducers.js
import { combineReducers } from 'redux';
import recordReducer from './reducers/recordReducer';
import loginReducer from './reducers/loginReducer';

const rootReducer = combineReducers({
  record: recordReducer,
  login: loginReducer,
});

export default rootReducer;
