import React, { useState } from 'react';
import { Box, Button, Modal, Typography, FormControl, InputLabel, Select, MenuItem, useTheme } from '@mui/material';
import { tokens } from "../../theme";

const ChangeRoleModal = ({ open, handleClose, handleRoleChange, roles, username }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectedRole, setSelectedRole] = useState('');

    const handleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleSubmit = () => {
        handleRoleChange(username, selectedRole);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box 
                display="flex" 
                flexDirection="column" 
                justifyContent="center" 
                alignItems="center" 
                p={3} 
                bgcolor={theme.palette.background.paper}
                color={theme.palette.text.primary}
                borderRadius={2}
                boxShadow={3}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '300px',
                }}
            >
                <Typography variant="h6" mb={2}>Change Role for {username}</Typography>
                <FormControl fullWidth variant="filled">
                    <InputLabel>Role</InputLabel>
                    <Select
                        value={selectedRole}
                        onChange={handleChange}
                        label="Role"
                    >
                        {roles.map((role) => (
                            <MenuItem key={role.id} value={role.name}>
                                {role.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box display="flex" justifyContent="end" mt="20px">
                    <Button onClick={handleSubmit} color="secondary" variant="contained">
                        Change Role
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ChangeRoleModal;
