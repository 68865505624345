import React from 'react';
import { Modal, Box, Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';

const ChangeDivisionModal = ({ open, handleClose, handleDivisionChange, username, divisions }) => {
    const [selectedDivision, setSelectedDivision] = React.useState('');

    const handleSave = () => {
        handleDivisionChange(username, selectedDivision);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ width: 400, padding: 2, margin: 'auto', marginTop: '10%' }}>
                <FormControl fullWidth variant="filled">
                    <InputLabel>Division</InputLabel>
                    <Select
                        value={selectedDivision}
                        onChange={(e) => setSelectedDivision(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                              {divisions.map((division) => (
                                  <MenuItem key={division.id} value={division.id}>
                                      {division.div_name}
                                  </MenuItem>
                              ))}
                    </Select>
                </FormControl>
                <Box mt={2}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleClose} style={{ marginLeft: 10 }}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ChangeDivisionModal;
