import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDrivers } from '../../redux/actions/recordAction';
import AddDriverModal from './AddDriverModal'; 

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const drivers = useSelector((state) => state.record.drivers) || [];
  const error = useSelector((state) => state.record.error);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchDrivers());
  }, [dispatch]);

  const handleCloseModal = () => {
    dispatch(fetchDrivers());
    setOpen(false);
   
  };

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "card_no",
      headerName: "Card Number",
      flex: 1,
    }
  ];
  
  return (
    <Box m="20px" >
     
    <Box display="flex" justifyContent="space-between" alignItems="center" >
      <Header title="DRIVERS" subtitle="Managing the Drivers" />
      
      
      <Button sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            
          }} onClick={() => setOpen(true)}>
        Add Driver
      </Button>
      <AddDriverModal open={open} handleClose={handleCloseModal} />


      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
              border: "none",
          },
          "& .MuiDataGrid-cell": {
              borderBottom: "none",
          },
          "& .name-column--cell": {
              color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
          },
      }}
  >
      
      <DataGrid
          rows={Array.isArray(drivers) ? drivers.map((driver, index) => ({...driver, id: index + 1})) : []}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Team;
