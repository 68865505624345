import React, { useEffect, useState } from 'react';
import { Box, useTheme } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup, LayersControl } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StatBox from "../../components/StatBox";
import vehicleIconUrl from './vehicle-icon.png';
import mqtt from 'mqtt';
import { fetchVehicles, fetchDrivers } from '../../redux/actions/recordAction';
import { useDispatch, useSelector } from 'react-redux';

const {
    REACT_APP_MQTT_URL,
    REACT_APP_MQTT_USERNAME,
    REACT_APP_MQTT_PASSWORD,
    REACT_APP_MQTT_IP,
    REACT_APP_MQTT_PORT,
    REACT_APP_MQTT_TOPIC
} = process.env;

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { BaseLayer } = LayersControl;
    const [vehicles, setVehicles] = useState({});
    const dispatch = useDispatch();
    const drivers = useSelector((state) => state.record.drivers) || [];
    const countVehicles = useSelector((state) => state.record.vehicles) || [];
    const userDivision = useSelector((state) => state.login.user?.division);
    
    
    
    const vehicleIcon = new L.Icon({
        iconUrl: vehicleIconUrl,
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32]
    });

    useEffect(() => {
        dispatch(fetchDrivers());
        dispatch(fetchVehicles());

      }, [dispatch]);
    

    useEffect(() => {
        const client = mqtt.connect(`${REACT_APP_MQTT_URL}`, {
            username: REACT_APP_MQTT_USERNAME,
            password: REACT_APP_MQTT_PASSWORD,
        });

        client.on('connect', () => {
            console.log('Connected to MQTT broker');
            client.subscribe(REACT_APP_MQTT_TOPIC, (err) => {
                if (!err) {
                    console.log('Subscribed to topic');
                }
            });
        });

        client.on('message', (topic, message) => {
            console.log('Message received:', message.toString());
            try {
                const data = JSON.parse(message.toString());
                const divison = "None";
                // const { imei, latitude, longitude } = data;
                const { imei, latitude, longitude, division } = data;
                if (latitude && longitude) {
                    if (userDivision === division) {
                        setVehicles((prevVehicles) => ({
                            ...prevVehicles,
                            [imei]: {...data, latitude: parseFloat(latitude), longitude: parseFloat(longitude)}
                        }));

                    } else if (userDivision === divison || userDivision === '') {
                        setVehicles((prevVehicles) => ({
                            ...prevVehicles,
                            [imei]: {...data, latitude: parseFloat(latitude), longitude: parseFloat(longitude)}
                        }));

                    }
                }else {
                    console.error('Invalid latitude or longitude in message');
                }
            } catch (error) {
                console.error('Failed to parse message', error);
            }
        });

        client.on('error', (error) => {
            console.error('MQTT Error:', error);
        });

        client.on('close', () => {
            console.log('MQTT connection closed');
        });

        return () => {
            client.end();
        };
    // }, []);
    }, [userDivision]);

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="DASHBOARD" />
            </Box>
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridTemplateRows="repeat(5, 1fr)"
                gap="20px"
            >
                <Box
                    gridColumn="span 3"
                    gridRow="span 1"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title={countVehicles.length}
                        subtitle="Vehicles"
                        progress="0.75"
                        increase="+14%"
                        icon={
                            <DirectionsCarIcon
                                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                </Box>
                <Box
                    gridColumn="span 3"
                    gridRow="span 1"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title={drivers.length}
                        subtitle="Drivers"
                        progress="0.50"
                        increase="+21%"
                        icon={
                            <PeopleAltIcon
                                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                </Box>
                <Box
                    gridColumn="span 3"
                    gridRow="span 1"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title="10"
                        subtitle="Active Vehicles"
                        progress="0.30"
                        increase="+5%"
                        icon={
                            <DirectionsCarIcon
                                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                </Box>
                <Box
                    gridColumn="span 12"
                    gridRow="span 17"
                    backgroundColor={colors.primary[400]}
                >
                    <Box height="100%" width="100%">
                        <MapContainer 
                            center={[0.0236, 37.9062]} 
                            zoom={6} 
                            style={{ height: "100%", width: "100%" }}
                        >
                            <LayersControl position="topright">
                                <BaseLayer checked name="OpenStreetMap">
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    />
                                </BaseLayer>
                                <BaseLayer name="OpenTopoMap">
                                    <TileLayer
                                        url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="https://opentopomap.org">OpenTopoMap</a> contributors'
                                    />
                                </BaseLayer>
                                <BaseLayer name="Stamen Toner">
                                    <TileLayer
                                        url="https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="http://maps.stamen.com/">Stamen Design</a>'
                                    />
                                </BaseLayer>
                            </LayersControl>
                            {Object.values(vehicles).map(vehicle => (
                                <Marker
                                    key={vehicle.imei}
                                    position={[vehicle.latitude, vehicle.longitude]}
                                    icon={vehicleIcon}
                                >
                                    <Popup>
                                        <div>
                                            {/* <p><strong>IMEI:</strong> {vehicle.imei}</p> */}
                                            <p><strong>CV Plate:</strong> {vehicle.civilian_plate}</p>
                                            
                                            <p><strong>RFID:</strong> {vehicle.rfid ? vehicle.rfid : 'NO_RFID'}</p>
                                            <p><strong>Driver:</strong> {vehicle.driver_name}</p>
                                            
                                            <p><strong>Timestamp:</strong> {vehicle.timestamp}</p>
                                            <p><strong>Num Satellite:</strong> {vehicle.num_satellite}</p>
                                            <p><strong>Altitude:</strong> {vehicle.altitude}</p>
                                            <p><strong>Course:</strong> {vehicle.course}</p>
                                            <p><strong>Speed:</strong> {vehicle.speed}</p>
                                        </div>
                                    </Popup>
                                </Marker>
                            ))}
                        </MapContainer>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
