import React, { useEffect, useState } from 'react';
import { Box, Button, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useDispatch, useSelector } from 'react-redux';
import { fetchRoles } from '../../redux/actions/recordAction';
import AddRolesModal from './AddRolesModal';

const Roles = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.record.roles) || [];
  const error = useSelector((state) => state.record.error);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  const handleCloseModal = () => {
    setOpen(false);
    dispatch(fetchRoles());
  };

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "name",
      headerName: "Role Name",
      flex: 1,
      cellClassName: "name-column--cell",
    }
  ];
  
  return (
    <Box m="20px" >
      <Box display="flex" justifyContent="space-between" alignItems="center" >
        <Header title="ROLES" subtitle="Managing the Roles" />
        <Button sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
        }} onClick={() => setOpen(true)}>
          Add Role
        </Button>
        <AddRolesModal open={open} handleClose={handleCloseModal} />
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
              border: "none",
          },
          "& .MuiDataGrid-cell": {
              borderBottom: "none",
          },
          "& .name-column--cell": {
              color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={roles.map((role, index) => ({...role, id: index + 1}))}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Roles;
