import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { addCarMake, updateCarMake } from '../../redux/actions/recordAction';

const CarMakeModal = ({ open, onClose, carMake }) => {
  const [makeName, setMakeName] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (carMake) {
      setMakeName(carMake.make_name);
    }
  }, [carMake]);

  const handleSubmit = () => {
    if (carMake) {
      dispatch(updateCarMake(carMake.id, makeName));
    } else {
      dispatch(addCarMake(makeName));
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{carMake ? 'Edit Car Make' : 'Add Car Make'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="make_name"
          label="Car Make Name"
          type="text"
          fullWidth
          value={makeName}
          onChange={e => setMakeName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>{carMake ? 'Update' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CarMakeModal;
