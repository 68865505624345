import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Modal, Typography, useTheme, MenuItem, Select, FormControl, InputLabel, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateVehicleAssignment } from '../../redux/actions/recordAction';
import { tokens } from "../../theme";

const AssignVehicleModal = ({ open, handleClose, vehicle, divisions, subdivisions, departments, isEditing }) => {
  const [assignedDivision, setAssignedDivision] = useState('');
  const [assignedSubdivision, setAssignedSubdivision] = useState('');
  const [assignedDepartment, setAssignedDepartment] = useState('');
  const [assignedCategory, setAssignedCategory] = useState('pool');
  const [assignedPerson, setAssignedPerson] = useState('');
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (isEditing && vehicle) {
      setAssignedDivision(vehicle.assigned_division);
      setAssignedSubdivision(vehicle.assigned_subdivision);
      setAssignedDepartment(vehicle.assigned_department);
      setAssignedCategory(vehicle.assigned_category);
      setAssignedPerson(vehicle.assigned_person);
    } else {
      setAssignedDivision('');
      setAssignedSubdivision('');
      setAssignedDepartment('');
      setAssignedCategory('pool');
      setAssignedPerson('');
    }
  }, [isEditing, vehicle]);

  const handleUpdateVehicle = () => {
    if (isEditing && vehicle) {
      dispatch(updateVehicleAssignment(
        {
        // vehicle: vehicle.vehicle,
        assigned_division: assignedDivision,
        assigned_subdivision: assignedSubdivision,
        assigned_department: assignedDepartment,
        assigned_category: assignedCategory,
        assigned_person: assignedPerson
      }));
    }
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        p={3} 
        bgcolor={theme.palette.background.paper}
        color={theme.palette.text.primary}
        borderRadius={2}
        boxShadow={3}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '600px',
        }}
      >
        <Typography variant="h6" mb={2}>{isEditing ? "Edit Vehicle Assignment" : "Assign Vehicle"}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel style={{ color: theme.palette.text.primary }}>Division</InputLabel>
              <Select
                value={assignedDivision}
                onChange={(e) => setAssignedDivision(e.target.value)}
                fullWidth
                style={{ color: theme.palette.text.primary }}
              >
                {divisions.map((div) => (
                  <MenuItem key={div._id} value={div._id}>
                    {div.div_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel style={{ color: theme.palette.text.primary }}>Subdivision</InputLabel>
              <Select
                value={assignedSubdivision}
                onChange={(e) => setAssignedSubdivision(e.target.value)}
                fullWidth
                style={{ color: theme.palette.text.primary }}
              >
                {subdivisions?.map((subdiv) => (
                  <MenuItem key={subdiv.id} value={subdiv.id}>
                    {subdiv.subdiv_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel style={{ color: theme.palette.text.primary }}>Department</InputLabel>
              <Select
                value={assignedDepartment}
                onChange={(e) => setAssignedDepartment(e.target.value)}
                fullWidth
                style={{ color: theme.palette.text.primary }}
              >
                {departments.map((dept) => (
                  <MenuItem key={dept._id} value={dept._id}>
                    {dept.department_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="Category" 
              value={assignedCategory} 
              onChange={(e) => setAssignedCategory(e.target.value)} 
              fullWidth 
              InputLabelProps={{
                style: { color: theme.palette.text.primary }
              }}
              InputProps={{
                style: { color: theme.palette.text.primary }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="Assigned Person" 
              value={assignedPerson} 
              onChange={(e) => setAssignedPerson(e.target.value)} 
              fullWidth 
              InputLabelProps={{
                style: { color: theme.palette.text.primary }
              }}
              InputProps={{
                style: { color: theme.palette.text.primary }
              }}
            />
          </Grid>
        </Grid>
        <Button 
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            mt: 3
          }} 
          onClick={handleUpdateVehicle}
        >
          {isEditing ? "Update Assignment" : "Assign Vehicle"}
        </Button>
      </Box>
    </Modal>
  );
};

export default AssignVehicleModal;
