import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCarMakes, addCarMake, updateCarMake, deleteCarMake, fetchCarModels, addCarModel, updateCarModel, deleteCarModel } from '../../redux/actions/recordAction';
import CarMakeModal from './CarMakeModal';
import CarModelModal from './CarModelModal';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ModelIcon from '@mui/icons-material/Build';

const CarMakes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const carMakes = useSelector(state => state.record.carMakes) || [];
  const carModels = useSelector(state => state.record.carModels) || [];
  const [openMakeModal, setOpenMakeModal] = useState(false);
  const [currentCarMake, setCurrentCarMake] = useState(null);
  const [isEditingMake, setIsEditingMake] = useState(false);
  const [openModelModal, setOpenModelModal] = useState(false);
  const [currentModel, setCurrentModel] = useState(null);
  const [selectedMakeId, setSelectedMakeId] = useState(null);
  const [isEditingModel, setIsEditingModel] = useState(false);
  const [viewModels, setViewModels] = useState(false); // State to toggle view models

  useEffect(() => {
    dispatch(fetchCarMakes());
  }, [dispatch]);

  const handleOpenMakeModal = (carMake = null) => {
    setCurrentCarMake(carMake);
    setIsEditingMake(!!carMake);
    setOpenMakeModal(true);
  };

  const handleCloseMakeModal = () => {
    setOpenMakeModal(false);
    setCurrentCarMake(null);
    setIsEditingMake(false);
  };

  const handleOpenModelModal = (makeId, model = null) => {
    setSelectedMakeId(makeId);
    setCurrentModel(model);
    setIsEditingModel(!!model);
    setOpenModelModal(true);
  };

  const handleCloseModelModal = () => {
    setOpenModelModal(false);
    setCurrentModel(null);
    setSelectedMakeId(null);
    setIsEditingModel(false);
  };

  const handleSaveModel = (modelData) => {
    if (isEditingModel) {
      dispatch(updateCarModel(modelData.id, modelData));
    } else {
      dispatch(addCarModel(modelData));
    }
    handleCloseModelModal();
  };

  const handleDeleteMake = (make_id) => {
    dispatch(deleteCarMake(make_id));
  };

  const handleDeleteModel = (model_id) => {
    dispatch(deleteCarModel(model_id));
  };

  const handleViewModels = (makeId) => {
    setSelectedMakeId(makeId);
    dispatch(fetchCarModels(makeId));
    setViewModels(true);
  };

  const handleBackToMakes = () => {
    setViewModels(false);
    setSelectedMakeId(null);
  };

  const makeColumns = [
    { field: "make_name", headerName: "Car Make", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <IconButton onClick={() => handleOpenMakeModal(params.row)} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteMake(params.row.id)} color="secondary">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Manage Models">
            <IconButton onClick={() => handleViewModels(params.row.id)} color="info">
              <ModelIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const modelColumns = [
    { field: "model_no", headerName: "Model Number", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <IconButton onClick={() => handleOpenModelModal(selectedMakeId, params.row)} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteModel(params.row.id)} color="secondary">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Box m={"20px"}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Car Makes" subtitle="Manage car makes" />
        {!viewModels ? (
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              mb: "20px"
            }}
            onClick={() => handleOpenMakeModal()}
          >
            Add Car Make
          </Button>
        ) : (
          <Box display="flex" gap="10px">
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                mb: "20px"
              }}
              onClick={handleBackToMakes}
            >
              Back to Car Makes
            </Button>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                mb: "20px"
              }}
              onClick={() => handleOpenModelModal(selectedMakeId)}
            >
              Add Car Model
            </Button>
          </Box>
        )}
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {!viewModels ? (
          <DataGrid
            rows={carMakes}
            columns={makeColumns}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        ) : (
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid
              rows={carModels}
              columns={modelColumns}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </Box>
        )}
      </Box>
      {openMakeModal && (
        <CarMakeModal
          open={openMakeModal}
          onClose={handleCloseMakeModal}
          carMake={currentCarMake}
          isEditing={isEditingMake}
        />
      )}
      {openModelModal && (
        <CarModelModal
          open={openModelModal}
          onClose={handleCloseModelModal}
          carModel={currentModel}
          onSave={handleSaveModel}
          isEditing={isEditingModel}
          carMakeId={selectedMakeId}
        />
      )}
    </Box>
  );
};

export default CarMakes;
