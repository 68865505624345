import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Modal, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { addDriver, fetchDrivers } from '../../redux/actions/recordAction';

const AddDriverModal = ({ open, handleClose }) => {
  const [cardNo, setCardNo] = useState('');
  const [fullName, setFullName] = useState('');
  const dispatch = useDispatch();
  const theme = useTheme();
  
  const colors = tokens(theme.palette.mode);

  const handleAddDriver = () => {
    dispatch(addDriver(cardNo, fullName));
    handleClose();
    dispatch(fetchDrivers);
    };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        p={3} 
        bgcolor={theme.palette.background.paper}
        color={theme.palette.text.primary}
        borderRadius={2}
        boxShadow={3}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '300px',
        }}
      >
        <Typography variant="h6" mb={2}>Add Driver</Typography>
        <TextField 
          label="Card Number" 
          value={cardNo} 
          onChange={(e) => setCardNo(e.target.value)} 
          fullWidth 
          mb={2} 
          style={{ marginBottom: '16px' }}
          InputLabelProps={{
            style: { color: theme.palette.text.primary }
          }}
          InputProps={{
            style: { color: theme.palette.text.primary  }
          }}
        />
        <TextField 
          label="Full Name" 
          value={fullName} 
          onChange={(e) => setFullName(e.target.value)} 
          fullWidth 
          mb={2} 
          style={{ marginBottom: '16px' }}
          InputLabelProps={{
            style: { color: theme.palette.text.primary }
          }}
          InputProps={{
            style: { color: theme.palette.text.primary }
          }}
        />
        <Button 
        sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            
          }} onClick={handleAddDriver}>Add Driver</Button>
      </Box>
    </Modal>
  );
};

export default AddDriverModal;
