import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Modal, Typography, useTheme, MenuItem, Select, FormControl, InputLabel, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { editDepartment, fetchDivisions, fetchSubdivisions } from '../../redux/actions/recordAction';
import { tokens } from "../../theme";

const EditDepartmentModal = ({ open, handleClose, department }) => {
  const [division, setDivision] = useState('');
  const [subdivName, setSubdivName] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const divisions = useSelector((state) => state.record.divisions) || [];
  const subdivisions = useSelector((state) => state.record.subdivisions) || [];

  useEffect(() => {
    if (open) {
      dispatch(fetchDivisions());
      dispatch(fetchSubdivisions());
      if (department) {
        setDivision(department.division);
        setSubdivName(department.subdiv_name);
        setDepartmentName(department.department_name);
      }
    }
  }, [open, department, dispatch]);

  const handleEditDepartment = () => {
    dispatch(editDepartment(department._id.$oid, division, subdivName, departmentName));
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        p={3} 
        bgcolor={theme.palette.background.paper}
        color={theme.palette.text.primary}
        borderRadius={2}
        boxShadow={3}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '600px',
        }}
      >
        <Typography variant="h6" mb={2}>Edit Department</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel style={{ color: theme.palette.text.primary }}>Division</InputLabel>
              <Select
                value={division}
                onChange={(e) => setDivision(e.target.value)}
                fullWidth
                style={{ color: theme.palette.text.primary }}
              >
                <MenuItem value="">
                <em>None</em>
                </MenuItem>
                  {divisions.map((division) => (
                      <MenuItem key={division.id} value={division.id}>
                          {division.div_name}
                      </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel style={{ color: theme.palette.text.primary }}>Subdivision</InputLabel>
              <Select
                value={subdivName}
                onChange={(e) => setSubdivName(e.target.value)}
                fullWidth
                style={{ color: theme.palette.text.primary }}
              >
                {subdivisions.map((subdiv) => (
                  <MenuItem key={subdiv._id.$oid} value={subdiv._id.$oid}>
                    {subdiv.subdiv_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField 
              label="Department Name" 
              value={departmentName} 
              onChange={(e) => setDepartmentName(e.target.value)} 
              fullWidth 
              InputLabelProps={{
                style: { color: theme.palette.text.primary }
              }}
              InputProps={{
                style: { color: theme.palette.text.primary }
              }}
            />
          </Grid>
        </Grid>
        <Button 
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            mt: 3
          }} 
          onClick={handleEditDepartment}
        >
          Edit Department
        </Button>
      </Box>
    </Modal>
  );
};

export default EditDepartmentModal;
